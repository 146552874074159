<template>
  <div class="d-flex arrows-container">
    <v-btn
      v-if="!isLastStep && currentStepName !== 'PackageSelection'"
      icon
      :class="nextButtonClass"
      @click="moveToNextStep"
    >
      <v-avatar
        size="40"
        class="v-avatar-light-bg"
      >
        <v-icon
          v-if="$vuetify.rtl"
          size="40"
        >
          {{ icons.mdiChevronLeft }}
        </v-icon>
        <v-icon
          v-else
          size="40"
        >
          {{ icons.mdiChevronRight }}
        </v-icon>
      </v-avatar>
    </v-btn>
    <v-btn
      v-if="!isFirstStep"
      icon
      :class="prevButtonClass"
      @click="moveToPreviousStep"
    >
      <v-avatar
        size="40"
        class="v-avatar-light-bg"
      >
        <v-icon
          v-if="$vuetify.rtl"
          size="40"
        >
          {{ icons.mdiChevronRight }}
        </v-icon>
        <v-icon
          v-else
          size="40"
        >
          {{ icons.mdiChevronLeft }}
        </v-icon>
      </v-avatar>
    </v-btn>
  </div>
</template>

<script>
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js'

export default {
  props: {
    moveToNextStep: {
      type: Function,
      required: true,
    },
    moveToPreviousStep: {
      type: Function,
      required: true,
    },
    stepsCount: {
      type: Number,
      required: true,
    },
    currentStep: {
      type: Number,
      required: true,
    },
    currentStepName: {
      required: true,
      default: null,
    },
  },
  computed: {
    isFirstStep() {
      return this.currentStep === 0
    },
    isLastStep() {
      return this.currentStep === this.stepsCount - 1
    },
    nextButtonClass() {
      return this.$vuetify.rtl ? 'floating-button button-left' : 'floating-button button-right'
    },
    prevButtonClass() {
      return this.$vuetify.rtl ? 'floating-button button-right' : 'floating-button button-left'
    },
  },
  setup() {
    return {
      icons: {
        mdiChevronLeft,
        mdiChevronRight,
      },
    }
  },
}
</script>

<style scoped>
.button-left {
  position: absolute;
  left: 2px;
}

.button-right {
  position: absolute;
  right: 2px;
}

.arrows-container {
  position: fixed;
  top: 45vh;
  z-index: 999999;

  width: inherit;
  left: 0;
  right: 0;
  max-width: inherit;
  margin: 0 auto;
}
</style>
