// src/stepMappings.js
export const stepComponents = {
    WelcomePagePreview: () =>
        import ('@/components/TourGuide/TourGuideSteps/WelcomePagePreview.vue'),
    InvitationPagePreview: () =>
        import ('@/components/TourGuide/TourGuideSteps/InvitationPagePreview.vue'),
    PackageSelection: () =>
        import ('@/components/TourGuide/TourGuideSteps/PackageSelection.vue'),
    MessagesPreview: () =>
        import ('@/components/TourGuide/TourGuideSteps/MessagesPreview.vue'),
    CallsPreview: () =>
        import ('@/components/TourGuide/TourGuideSteps/CallsPreview.vue'),
    TimingPreview: () =>
        import ('@/components/TourGuide/TourGuideSteps/TimingPreview.vue'),
    UploadGuestsPreview: () =>
        import ('@/components/TourGuide/TourGuideSteps/UploadGuestsPreview.vue'),
}

export const stepImages = {
    WelcomePagePreview: [require('@/assets/images/tour-guide/greenClockVector.png')],
    PackageSelection: [require('@/assets/images/tour-guide/conversation.png')],
    UploadGuestsPreview: [require('@/assets/images/tour-guide/contacts-book.png')],
}