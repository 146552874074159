<template>
  <div id="tour-guide-cards">
    <v-dialog
      v-model="$store.state.tourGuideShow.value"
      fullscreen
      hide-overlay
      persistent
      scrollable
    >
      <v-container
        id="tour-guide-dialog-container"
        fluid
        class="d-flex justify-center dialog-container"
        :style="$vuetify.breakpoint.width > 300 ? 'background: #fafafa;' : ''"
      >
        <div
          v-if="loading"
          class="loading-container"
        >
          <v-progress-circular
            :size="40"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        <div
          v-else
          class="d-flex justify-center card-container"
        >
          <v-card
            flat
            color="veryLightGreen"
            class="centered-card pb-0"
            :style="`height: 100%; width: 100%; ${$vuetify.breakpoint.xsOnly ? '' : 'max-width: 390px;'} ${
              $vuetify.breakpoint.smAndDown ? 'height: 100%;' : 'margin-top: 20px;'
            }`"
          >
            <div class="justify-center align-center text-center fixed sticky-story">
              <v-btn
                v-if="$store.state.tourGuideShow.type !== 'newEvent'"
                color="transparent"
                text
                class="pa-0 ma-0"
                @click="closeTourGuide"
              >
                <div class="black--text">
                  {{ $t('Close') }}
                </div>
              </v-btn>
              <div
                v-else
                class="pt-4"
              ></div>
              <ProgressBar
                v-if="steps[currentStep]"
                :current-step-name="steps[currentStep].name"
                :current-step="currentStep"
                :steps-count="steps.length"
                :move-to-next-step="autoMoveToNextStep"
                class="pb-5"
              ></ProgressBar>
            </div>
            <arrows
              :current-step="currentStep"
              :steps-count="steps.length"
              :move-to-next-step="moveToNextStep"
              :move-to-previous-step="moveToPreviousStep"
              :current-step-name="steps[currentStep].name"
            >
            </arrows>
            <div ref="tourGuideTop">
              <div class="card-content mt-8">
                <div
                  v-for="(step, index) in steps"
                  v-show="currentStep === index"
                  :key="index"
                >
                  <component
                    :is="step.component"
                    :step-index="index"
                    :current-step="currentStep"
                    :steps-count="steps.length"
                    :move-to-next-step="moveToNextStep"
                    :move-to-previous-step="moveToPreviousStep"
                    :selected-package.sync="selectedPackage"
                  ></component>
                </div>
              </div>
            </div>
          </v-card>
        </div>
      </v-container>
    </v-dialog>
  </div>
</template>

<script>
import { getFullImageUrl } from '@/@core/utils/ImageFromApi'
import '@/assets/tour-guide-animations.css'
import Arrows from '@/components/TourGuide/Arrows.vue'
import ProgressBar from '@/components/TourGuide/ProgressBar.vue'
import store from '@/store'
import useAppConfig from '@core/@app-config/useAppConfig'
import { computed, onMounted, onUnmounted, ref } from '@vue/composition-api'
import Hammer from 'hammerjs'
import Vue from 'vue'
import { stepComponents, stepImages } from './stepMappings'

export default {
  components: { ProgressBar, Arrows },
  setup() {
    const loading = ref(true)
    const selectedPackage = ref(null)
    const { isRtl } = useAppConfig()

    const plannedSteps = ref([])
    const steps = computed(() => plannedSteps.value.filter(step => step.isVisible()))

    const currentStep = ref(0)
    const tourGuideTop = ref(null)

    const hammer = ref(null)

    const scrollToTop = () => {
      if (tourGuideTop.value) {
        tourGuideTop.value.scrollIntoView({ behavior: 'smooth' })
      }
    }

    const initializeSteps = async () => {
      plannedSteps.value = []
      plannedSteps.value = [
        { name: 'WelcomePagePreview', component: null, isVisible: () => true },
        { name: 'InvitationPagePreview', component: null, isVisible: () => true },
        {
          name: 'PackageSelection',
          component: null,
          disableAutoMove: true,
          isVisible: () => store.state.eventData.progressStatus === 0,
        },
        { name: 'MessagesPreview', component: null, isVisible: () => true },
        { name: 'CallsPreview', component: null, isVisible: () => store.state.eventData.includeCallsPreference },
        { name: 'TimingPreview', component: null, isVisible: () => true },
        { name: 'UploadGuestsPreview', component: null, isVisible: () => true },
      ]

      const loadComponents = plannedSteps.value.map(step =>
        stepComponents[step.name]().then(module => {
          // eslint-disable-next-line no-param-reassign
          step.component = module.default
        }),
      )

      const imagePromises = plannedSteps.value.flatMap(step =>
        (stepImages[step.name] || []).map(src => Vue.prototype.$preloadImage(src)),
      )

      if (store.state.eventData.image) {
        imagePromises.push(Vue.prototype.$preloadImage(getFullImageUrl(store.state.eventData.image)))
      }

      await Promise.all([...loadComponents, ...imagePromises])
      loading.value = false
    }

    const moveToNextStep = () => {
      if (!store.state.activeCall) {
        if (
          (currentStep.value < steps.value.length - 1 && steps.value[currentStep.value].name !== 'Package') ||
          selectedPackage.value
        ) {
          scrollToTop()
          currentStep.value += 1
        }
      }
    }

    const autoMoveToNextStep = () => {
      if (!steps.value[currentStep.value]?.disableAutoMove) {
        moveToNextStep()
      }
    }

    const moveToPreviousStep = () => {
      if (!store.state.activeCall && currentStep.value > 0) {
        scrollToTop()
        currentStep.value -= 1
      }
    }

    const closeTourGuide = () => {
      currentStep.value = 0
      store.commit('setTourGuide', { value: false, type: '' })
    }

    onMounted(() => {
      const swipeArea = document.getElementById('tour-guide-dialog-container')
      hammer.value = new Hammer.Manager(swipeArea, {
        touchAction: 'auto',
        inputClass: Hammer.TouchInput,
        recognizers: [[Hammer.Swipe, { direction: Hammer.DIRECTION_HORIZONTAL }]],
      })

      const swipeNext = () => {
        const isLastStep = currentStep.value === steps.value.length - 1
        if (isLastStep || steps.value[currentStep.value].name === 'PackageSelection') {
          return
        }

        moveToNextStep()
      }

      hammer.value.on('swipeleft', () => {
        if (isRtl.value) {
          moveToPreviousStep()
        } else {
          swipeNext()
        }
      })

      hammer.value.on('swiperight', () => {
        if (isRtl.value) {
          swipeNext()
        } else {
          moveToPreviousStep()
        }
      })

      initializeSteps()
    })

    // Clean up Hammer.js instance on unmount
    onUnmounted(() => {
      if (hammer.value) {
        hammer.value.off('swipeleft', moveToNextStep)
        hammer.value.off('swiperight', moveToPreviousStep)
        hammer.value.destroy()
      }
    })

    return {
      currentStep,
      steps,
      moveToNextStep,
      moveToPreviousStep,
      selectedPackage,
      closeTourGuide,
      loading,
      tourGuideTop,
      autoMoveToNextStep,
    }
  },
}
</script>

<style scoped>
.centered-card {
  width: 340px;
  display: flex;
  flex-direction: column;
}

.card-content {
  margin-top: 0;
  padding-top: 0;
}

.v-btn {
  margin-bottom: 10px;
}

.progress-bar {
  margin-bottom: 0;
}

.sticky-story {
  width: inherit;
  background: #f5f5f5;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-width: inherit;
  color: white;
  margin: 0 auto;
}

.dialog-container {
  padding: 0px;
  min-height: 100vh;
  position: absolute;
  overflow: hidden;
}

.card-container {
  width: 100%;
  overflow-y: scroll;
}

#tour-guide-dialog-container {
  overflow-y: auto;
  touch-action: pan-y !important;
  background: orange;
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}
</style>
