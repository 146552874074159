<template>
  <v-row
    no-gutters
    class="progress-row"
    justify="center"
  >
    <v-col
      v-for="(step, index) in stepsCount"
      :key="index"
      :cols="Math.floor(12 / stepsCount)- 0.1"
      class="progress-segment"
    >
      <div
        class="segment-inner"
        :style="segmentStyle(index)"
      ></div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    currentStepName: {
      type: String,
      required: true,
    },
    stepsCount: {
      type: Number,
      required: true,
    },
    currentStep: {
      type: Number,
      required: true,
    },
    moveToNextStep: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      totalDuration: 10000, // Total duration of 10 seconds per step
      localCurrentStep: this.currentStep,
      progress: Array(this.stepsCount).fill(0), // Initialize progress for each step
      intervalId: null,
      updateFrequency: 50, // Frequency of updates in milliseconds
    }
  },
  watch: {
    currentStep(newStep) {
      const durationStepMapping = {
        'MessagesPreview': 20000,
      };
      
      this.totalDuration = durationStepMapping[this.currentStepName] || 10000;

      this.localCurrentStep = newStep
      this.resetProgress()
      this.startProgress()
    },
  },
  mounted() {
    this.startProgress()
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId) // Ensure cleanup of the interval
    }
  },
  methods: {
    resetProgress() {
      if (this.intervalId) {
        clearInterval(this.intervalId) // Clear any existing interval
      }
      this.progress = Array(this.stepsCount).fill(0) // Reset progress for each step
    },
    startProgress() {
      const totalUpdates = this.totalDuration / this.updateFrequency // Total number of updates
      const increment = 100 / totalUpdates // Increment per update

      const updateProgress = () => {
        if (this.localCurrentStep <= this.stepsCount) {
          if (this.progress[this.localCurrentStep] < 100) {
            this.progress[this.localCurrentStep] += increment

            if (this.progress[this.localCurrentStep] > 100) {
              this.progress[this.localCurrentStep] = 100
            }
            this.$set(this.progress, this.localCurrentStep, this.progress[this.localCurrentStep])

            // Ensure previous steps are 100%
            for (let i = 0; i < this.localCurrentStep; i += 1) {
              this.progress[i] = 100
              this.$set(this.progress, i, this.progress[i])
            }
          } else if (this.localCurrentStep + 1 < this.stepsCount) {
            this.moveToNextStep()
          } else {
            clearInterval(this.intervalId) // Finish the progress
          }
        }
      }

      // Start the progress immediately
      updateProgress()

      // Continue updating progress at intervals
      this.intervalId = setInterval(updateProgress, this.updateFrequency)
    },
    segmentStyle(index) {
      return {
        width: `${this.progress[index]}%`,
        transition: `width ${this.updateFrequency}ms linear`,
        backgroundColor:
          index < this.localCurrentStep ? '#878580' : index === this.localCurrentStep ? '#878580' : '#e0e0e0',
      }
    },
  },
}
</script>

<style scoped>
.progress-bar {
  width: 100%;
  height: 100%;
}

.progress-row {
  gap: 8px;
  padding: 20px;
  padding-top: 5px;
  flex: none;
}

.progress-segment {
  height: 2px;
  background-color: #e0e0e0;
  position: relative;
  overflow: hidden;
  margin: 0px;
  padding: 0px;
}

.segment-inner {
  height: 100%;
}
</style>
